import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledContent = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spaces[4],
}));

export const StyledGrid = styled.div(({ theme }) => ({
    display: 'grid',
    gap: theme.spaces[4],

    [breakpoints.md]: {
        gridTemplateColumns: '1fr 1fr',
        gap: theme.spaces[5],
    },
}));

export const StyledImageContainer = styled.div({
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    aspectRatio: '4 / 3',
});

export const StyledPromotedContent = styled.div({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
});
